import React from 'react';

const Loading = () => {
  const loadingImg = '/loading.svg';

  return (
    <div className="spinner">
      <img src={loadingImg} alt="Loading..." />
    </div>
  );
};

export default Loading;
