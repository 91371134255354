import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

import "./DailyReportPreview.css"

const DailyReportPreview = (props) => {

    const showVar = (str) => {
        return str === '' || str == null ? <>&nbsp;</> : str;
    }

    return (
        <div id="">
            <div id={props.isRotated ? "dailyReportPreviewContainerRotate" : "dailyReportPreviewContainer"} className="page-container">
            <Container>
                <Row className='m-4 text-end'>
                    <Col>
                        <Button onClick={showVar(props.hidePreview)} color="secondary">Close</Button>
                    </Col>
                </Row>
            </Container>
                <div
                    id="html"
                    className="page-container"
                    style={{
                        padding: 25,
                        width: 720,
                        margin: "0 auto",
                        boxShadow: "3px 3px 10px rgba(0,0,0,.5)"
                    }}
                >
                    <div
                        className="page"
                        style={{ pageBreakAfter: "none", width: "auto", margin: "auto" }}
                    >
                        <table
                            cellSpacing={0}
                            cellPadding={0}
                            id="header"
                            style={{ border: "0 none", width: "100%" }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="logoArea"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "50%",
                                            textAlign: "left",
                                            fontSize: 9
                                        }}
                                    >
                                        <img
                                            src={props.logo}
                                            alt={props.title}
                                            id="logo"
                                            style={{ height: 80 }}
                                        />
                                    </td>
                                    <td
                                        id="titleArea"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "77%",
                                            textAlign: "right",
                                            whiteSpace: "nowrap",
                                            float: "right !important"
                                        }}
                                    >
                                        <br />
                                        <h1 style={{ fontSize: 30, textAlign: "right", margin: 0 }}>
                                            Daily Field Report
                                        </h1>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="job-area"
                            style={{
                                border: "0 none",
                                width: "100%",
                                margin: "8px auto",
                                fontSize: 14
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="JobNumber-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "5%",
                                            textAlign: "left",
                                            fontSize: '16px'
                                        }}
                                    >
                                        Job#
                                    </td>
                                    <td
                                        id="JobNumber-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "15%",
                                            fontSize: '16px'
                                        }}
                                    >
                                        {showVar(props.JobNumber)}
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="JobName-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "15%",
                                            textAlign: "right",
                                            fontSize: '16px'
                                        }}
                                    >
                                        Job Name
                                    </td>
                                    <td
                                        id="JobName-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "40%",
                                            fontSize: '16px'
                                        }}
                                    >
                                        {showVar(props.JobName)}
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="Date-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "7%",
                                            textAlign: "right",
                                            fontSize: '16px'
                                        }}
                                    >
                                        Date
                                    </td>
                                    <td
                                        id="Date-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "13%",
                                            fontSize: '16px'
                                        }}
                                    >
                                        {showVar(props.Date)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="description-area"
                            style={{
                                border: "0 none",
                                width: "100%",
                                margin: "8px auto",
                                fontSize: 14
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="DescriptionOfWork-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "20%",
                                            textAlign: "left",
                                            fontSize: '16px'
                                        }}
                                    >
                                        Description of Work
                                    </td>
                                    <td
                                        id="DescriptionOfWork-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "57%",
                                            fontSize: '16px'
                                        }}
                                    >
                                        {showVar(props.DescriptionOfWork)}
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="County-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "7%",
                                            textAlign: "right",
                                            fontSize: '16px'
                                        }}
                                    >
                                        County
                                    </td>
                                    <td
                                        id="County-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "13%",
                                            fontSize: '16px'
                                        }}
                                    >
                                        {showVar(props.County)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="billTo-area"
                            style={{
                                border: "0 none",
                                width: "100%",
                                margin: "8px auto",
                                fontSize: 14
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="BillTo-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "7%",
                                            textAlign: "left",
                                            fontSize: '16px'
                                        }}
                                    >
                                        Bill To
                                    </td>
                                    <td
                                        id="BillTo-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "65%",
                                            fontSize: '16px'
                                        }}
                                    >
                                        {showVar(props.BillTo)}
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="RFCO-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "11%",
                                            textAlign: "right",
                                            fontSize: '16px'
                                        }}
                                    >
                                        RFCO
                                    </td>
                                    <td
                                        id="RFCO-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "17%",
                                            fontSize: '16px'
                                        }}
                                    >
                                        {showVar(props.RFCO)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="labor"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th
                                        id="LaborCostCodes"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "10%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Cost Codes
                                    </th>
                                    <th
                                        id="LaborName"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "14%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Name
                                    </th>
                                    <th
                                        id="LaborTime"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Time
                                    </th>
                                    <th
                                        id="LaborEquipmentOperated"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "11%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Equipment Operated
                                    </th>
                                    <th
                                        id="LaborEquipmentNumber"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Equip. #
                                    </th>
                                    <th
                                        id="LaborReg"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "5%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Reg.
                                    </th>
                                    <th
                                        id="LaborRateReg"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "6%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="LaborOT"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "5%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        O.T.
                                    </th>
                                    <th
                                        id="LaborRateOT"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "6%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="LaborDT"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "5%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        D.T.
                                    </th>
                                    <th
                                        id="LaborRateDT"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "6%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="LaborAmount"
                                        className="bold"
                                        data-calculation="([LaborReg]*[LaborRateReg])+([LaborOT]*[LaborRateOT])+([LaborDT]*[LaborRateDT])"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold !important",
                                            textAlign: "center",
                                            fontSize: 12,
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000"
                                        }}
                                    >
                                        Amount
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName1)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT1)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount1)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName2)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT2)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName3)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT3)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount3)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName4)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT4)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount4)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName5)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT5)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount5)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName6)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT6)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount6)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName7)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT7)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount7)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes8)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName8)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime8)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated8)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber8)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg8)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg8)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT8)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT8)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT8)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT8)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount8)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes9)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName9)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime9)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated9)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber9)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg9)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg9)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT9)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT9)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT9)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT9)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount9)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes10)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName10)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime10)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated10)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber10)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg10)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg10)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT10)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT10)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT10)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT10)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount10)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes11)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName11)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime11)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated11)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber11)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg11)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg11)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT11)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT11)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT11)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT11)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount11)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes12)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName12)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime12)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated12)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber12)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg12)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg12)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT12)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT12)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT12)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT12)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount12)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes13)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName13)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime13)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated13)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber13)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg13)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg13)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT13)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT13)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT13)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT13)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount13)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes14)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName14)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime14)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated14)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber14)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg14)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg14)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT14)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT14)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT14)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT14)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount14)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes15)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName15)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime15)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated15)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber15)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg15)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg15)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT15)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT15)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT15)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT15)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount15)}
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes16)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName16)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime16)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated16)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber16)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg16)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg16)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT16)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT16)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT16)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT16)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount16)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes17)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName17)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime17)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated17)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber17)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg17)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg17)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT17)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT17)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT17)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT17)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount17)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes18)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName18)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime18)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated18)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber18)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg18)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg18)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT18)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT18)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT18)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT18)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount18)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes19)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName19)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime19)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated19)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber19)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg19)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg19)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT19)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT19)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT19)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT19)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount19)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes20)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName20)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime20)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated20)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber20)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg20)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg20)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT20)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT20)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT20)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT20)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount20)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes21)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName21)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime21)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated21)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber21)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg21)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg21)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT21)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT21)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT21)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT21)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount21)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborCostCodes22)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborName22)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborTime22)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentOperated22)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborEquipmentNumber22)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborReg22)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateReg22)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborOT22)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateOT22)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborDT22)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborRateDT22)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.LaborAmount22)}
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                        <table
                            id="equipmentRentals"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th
                                        id="EquipmentRentalsCostCodes"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "10%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Cost Codes
                                    </th>
                                    <th
                                        id="EquipmentRentalsCo"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "25%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Co.
                                    </th>
                                    <th
                                        id="EquipmentRentalsType"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Type
                                    </th>
                                    <th
                                        id="EquipmentRentalsSerialEquipmentNumber"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "17%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Serial/Equip #
                                    </th>
                                    <th
                                        id="EquipmentRentalsDay"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Day
                                    </th>
                                    <th
                                        id="EquipmentRentalsRate"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="EquipmentRentalsAmount"
                                        className="bold"
                                        data-calculation="([EquipmentRentalsDay]*[EquipmentRentalsRate])"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold !important",
                                            textAlign: "center",
                                            fontSize: 12,
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000"
                                        }}
                                    >
                                        Amount
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCostCodes1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCo1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsType1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsSerialEquipmentNumber1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsDay1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsRate1)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsAmount1)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCostCodes2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCo2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsType2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsSerialEquipmentNumber2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsDay2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsRate2)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsAmount2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCostCodes3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCo3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsType3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsSerialEquipmentNumber3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsDay3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsRate3)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsAmount3)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCostCodes4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCo4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsType4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsSerialEquipmentNumber4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsDay4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsRate4)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsAmount4)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCostCodes5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCo5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsType5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsSerialEquipmentNumber5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsDay5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsRate5)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsAmount5)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCostCodes6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCo6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsType6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsSerialEquipmentNumber6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsDay6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsRate6)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsAmount6)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCostCodes7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsCo7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsType7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsSerialEquipmentNumber7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsDay7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsRate7)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.EquipmentRentalsAmount7)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="hauling"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th
                                        id="HaulingCostCodes"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "10%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Cost Codes
                                    </th>
                                    <th
                                        id="HaulingCo"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "18%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Co.
                                    </th>
                                    <th
                                        id="HaulingTruckType"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "14%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Truck Type
                                    </th>
                                    <th
                                        id="HaulingMaterialType"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "14%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Material Type
                                    </th>
                                    <th
                                        id="HaulingLoads"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Loads
                                    </th>
                                    <th
                                        id="HaulingTons"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Tons
                                    </th>
                                    <th
                                        id="HaulingHours"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Hours
                                    </th>
                                    <th
                                        id="HaulingRate"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="HaulingAmount"
                                        className="bold"
                                        data-calculation="([HaulingLoads]*[HaulingRate])+([HaulingTons]*[HaulingRate])+([HaulingHours]*[HaulingRate])"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold !important",
                                            textAlign: "center",
                                            fontSize: 12,
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000"
                                        }}
                                    >
                                        Amount
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCostCodes1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCo1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTruckType1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingMaterialType1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingLoads1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTons1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingHours1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingRate1)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingAmount1)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCostCodes2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCo2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTruckType2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingMaterialType2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingLoads2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTons2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingHours2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingRate2)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingAmount2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCostCodes3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCo3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTruckType3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingMaterialType3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingLoads3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTons3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingHours3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingRate3)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingAmount3)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCostCodes4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCo4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTruckType4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingMaterialType4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingLoads4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTons4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingHours4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingRate4)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingAmount4)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCostCodes5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCo5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTruckType5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingMaterialType5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingLoads5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTons5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingHours5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingRate5)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingAmount5)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCostCodes6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCo6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTruckType6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingMaterialType6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingLoads6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTons6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingHours6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingRate6)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingAmount6)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCostCodes7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingCo7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTruckType7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingMaterialType7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingLoads7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingTons7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingHours7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingRate7)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.HaulingAmount7)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="suppliesAndMaterials"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th
                                        id="SuppliesAndMaterialsCostCodes"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "10%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Cost Codes
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsName"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "48%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Name
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsQuantity"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "14%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Quantity
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsUnit"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Unit
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsPrice"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        Price
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsAmount"
                                        className="bold"
                                        data-calculation="[SuppliesAndMaterialsQuantity]*[SuppliesAndMaterialsPrice]"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold !important",
                                            textAlign: "center",
                                            fontSize: 12,
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000"
                                        }}
                                    >
                                        Amount
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes1)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity1)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit1)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice1)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount1)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes2)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity2)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit2)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice2)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes3)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity3)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit3)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice3)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount3)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes4)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity4)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit4)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice4)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount4)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes5)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity5)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit5)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice5)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount5)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes6)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity6)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit6)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice6)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount6)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes7)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity7)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit7)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice7)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount7)}
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes8)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName8)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity8)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit8)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice8)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount8)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes9)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName9)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity9)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit9)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice9)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount9)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes10)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName10)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity10)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit10)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice10)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount10)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes11)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName11)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity11)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit11)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice11)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount11)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes12)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName12)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity12)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit12)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice12)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount12)}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsCostCodes13)}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsName13)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsQuantity13)}
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsUnit13)}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsPrice13)}
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        {showVar(props.SuppliesAndMaterialsAmount13)}
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                        <table
                            id="totals"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%",
                                height: 35
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="remarks"
                                        className="bold"
                                        style={{
                                            verticalAlign: "middle",
                                            textTransform: "uppercase",
                                            fontSize: 12,
                                            width: "10%",
                                            textAlign: "center",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontWeight: "bold !important"
                                        }}
                                    >
                                        Remarks
                                    </td>
                                    <td
                                        id="remarks-field"
                                        style={{
                                            verticalAlign: "middle",
                                            width: "70%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        {showVar(props.Remarks)}
                                    </td>
                                    <td
                                        id="total"
                                        className="bold"
                                        style={{
                                            verticalAlign: "middle",
                                            textTransform: "uppercase",
                                            fontSize: 12,
                                            width: "8%",
                                            textAlign: "center",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontWeight: "bold !important"
                                        }}
                                    >
                                        Total
                                    </td>
                                    <td
                                        id="total-field"
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "middle",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 12
                                        }}
                                    >
                                        {showVar(props.Total)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="signatures"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{ border: "0 none", width: "85%", margin: "0 auto" }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="authorizedBySignature-field"
                                        className="center textfield"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "40%",
                                            fontSize: '16px'
                                        }}
                                    >
                                        {props.AuthorizedBySignature != null && props.AuthorizedBySignature !== '' && <img src={showVar(props.AuthorizedBySignature)} alt="Authorized By Signature" className='signatureImagePreview' />}
                                    </td>
                                    <td
                                        className="spacer"
                                        style={{ verticalAlign: "bottom", width: "20%" }}
                                    >
                                        &nbsp;
                                    </td>
                                    <td
                                        id="foremanSignature-field"
                                        className="center textfield"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "40%",
                                            height: "50px",
                                            fontSize: '16px'
                                        }}
                                    >
                                        {props.ForemanSignature != null && props.ForemanSignature !== '' && <img src={showVar(props.ForemanSignature)} alt="Foreman Signature" className='signatureImagePreview' />}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className="center uppercase"
                                        style={{
                                            textAlign: "center",
                                            textTransform: "uppercase",
                                            verticalAlign: "bottom"
                                        }}
                                    >
                                        Authorized By
                                    </td>
                                    <td
                                        className="spacer"
                                        style={{ verticalAlign: "bottom", width: "20%" }}
                                    >
                                        &nbsp;
                                    </td>
                                    <td
                                        className="center uppercase"
                                        style={{
                                            textAlign: "center",
                                            textTransform: "uppercase",
                                            verticalAlign: "bottom"
                                        }}
                                    >
                                        Foreman
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default DailyReportPreview;